import * as React from 'react'
import Footer from '../components/Footer'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'
import {useTranslation} from 'react-i18next'
import createStyles from '@material-ui/core/styles/createStyles'
import {Grid, Paper, Theme, Typography, WithStyles, withStyles} from '@material-ui/core'
import ImageHeader from '../components/ImageHeader'
import GridFeature from '../components/GridFeature'
import Divider from '../components/Divider'
import classNames from 'classnames'
import PageContainer from '../components/PageContainer'
import StartFooter from '../components/StartFooter'

const calendarIcon = require('../assets/home/icons/calendar.png')
const avsIcon = require('../assets/home/icons/briefing.png')
const communicationIcon = require('../assets/home/icons/communication.png')

const interfaceImage = require('../assets/home/Interface.png')
const integrationsImage = require('../assets/home/Integrations.png')


const styles = (theme: Theme) =>
    createStyles({
        headerImage: {
            width: '100%', minHeight: 150, marginTop: '-3%', objectFit: 'cover'
        },
        title: {
            textTransform: 'uppercase', marginBottom: 2 * theme.spacing.unit
        },
        centerContainer: {
            textAlign: 'center', marginBottom: 2 * theme.spacing.unit
        },
        bigImageContainer: {
            width: '90%', maxWidth: 800, marginLeft: 'auto', marginRight: 'auto', marginTop: 2 * theme.spacing.unit
        },
        fullImage: {
            width: '100%'
        },
        integrationsContainer: {
            [theme.breakpoints.up('sm')]: {
                maxWidth: 1100, width: '100%', display: 'flex'
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        integrationsImageContainer: {
            [theme.breakpoints.up('sm')]: {
                width: 400, marginTop: 4 * theme.spacing.unit
            },
            [theme.breakpoints.down('xs')]: {
                width: '50%', margin: 'auto', marginBottom: 4 * theme.spacing.unit
            }
        },
        largeIntegrationsImage: {
            [theme.breakpoints.up('sm')]: {
                width: '90%', margin: 2 * theme.spacing.unit
            },
            [theme.breakpoints.down('xs')]: {
                width: '100%'
            }
        },
        integrationsTextContainer: {
            flexBasis: 400, flexGrow: 1, paddingLeft: 2 * theme.spacing.unit, paddingRight: 2 * theme.spacing.unit
        },
        smallLeftAlignment: {
            [theme.breakpoints.up('sm')]: {
                textAlign: 'center'
            }
        },
        trialContainer: {
            maxWidth: 600, marginLeft: 2 * theme.spacing.unit, marginRight: 2 * theme.spacing.unit
        }
    })

const Home = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <PageContainer>
            <ImageHeader/>

            <div><Grid container spacing={0} style={{maxWidth: 1200, width: '100%', marginTop: 50}}>
                <GridFeature
                    icon={avsIcon}
                    title={t('home.avs.title')}
                    text={t('home.avs.text')}
                />
                <GridFeature
                    icon={calendarIcon}
                    title={t('home.calendar.title')}
                    text={t('home.calendar.text')}
                />
                <GridFeature
                    icon={communicationIcon}
                    title={t('home.communication.title')}
                    text={t('home.communication.text')}
                />
            </Grid></div>

            <Divider/>

            <div className={props.classes.centerContainer}>
                <Typography variant="h4" className={props.classes.title}>{t('home.interface.title')}</Typography>
                <Typography>{t('home.interface.text')}</Typography>
                <Paper className={props.classes.bigImageContainer}>
                    <img src={interfaceImage} className={props.classes.fullImage}/>
                </Paper>
            </div>

            <Divider/>

            <div><div className={props.classes.integrationsContainer}>
                <div className={props.classes.integrationsImageContainer}>
                    <img src={integrationsImage} className={props.classes.largeIntegrationsImage}/>
                </div>
                <div className={props.classes.integrationsTextContainer}>
                    <Typography variant="h4" className={props.classes.title}>{t('home.integrations.title')}</Typography>
                    <Typography>{t('home.integrations.text')}
                    </Typography>
                </div>
            </div></div>

            <Divider/>

            <div className={classNames(props.classes.smallLeftAlignment, props.classes.trialContainer)}>
                <Typography variant="h4" className={props.classes.title}>
                    {t('home.trial.title')}
                </Typography>
                <Typography>
                    {t('home.trial.text')}
                </Typography>
            </div>

            <StartFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(Home)
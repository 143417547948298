import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Button, Hidden, Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'
const headerImage = require('../assets/home/header.jpg')

const styles = (theme: Theme) =>
    createStyles({
        container: {
            position: 'relative'
        },
        headerImage: {
            width: '100%', minHeight: 150, marginTop: '-3%', objectFit: 'cover'
        },
        teaserContainer: {
            position: 'absolute', right: '12%', bottom: '20%'
        },
        overImageTitle: {
            width: 330, marginBottom: '5%', color: 'white', textAlign: 'center'
        },
        underImageTitle: {
            width: '100%', marginBottom: '5%', textAlign: 'center'
        },

        button: {
            minWidth: 300, padding: 2 * theme.spacing.unit
        },
        buttonTitle: {
            color: 'white'
        }
    })

const CTAButton = withStyles(styles)((props: WithStyles<typeof styles>)  => {
    const {t} = useTranslation()
    return (
        <Button variant="contained" color="secondary" className={props.classes.button} component={props => <Link to={AppRoute.pricing} {...props as LinkProps}/>}>
            <Typography variant="h6" className={props.classes.buttonTitle}><b>
                {t('trial.teaser.button')}
            </b></Typography>
        </Button>
    )
})

const ImageHeader = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <React.Fragment>
            <div className={props.classes.container}>
                <img src={headerImage} className={props.classes.headerImage}/>
                <Hidden xsDown>
                    <div className={props.classes.teaserContainer}>
                        <Typography variant="h4" className={props.classes.overImageTitle}>
                            {t('home.title')}
                        </Typography>
                        <CTAButton/>
                    </div>
                </Hidden>
            </div>
            <Hidden smUp>
                <Typography variant="h4" className={props.classes.underImageTitle}>
                    {t('home.title')}
                </Typography>
                <CTAButton/>
            </Hidden>
        </React.Fragment>
    )
}

export default withStyles(styles)(ImageHeader)

import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

const styles = (theme: Theme) =>
    createStyles({
        divider: {
            background: theme.palette.grey.A100, height: 1, width: '100%',
            marginTop: 6 * theme.spacing.unit, marginBottom: 6 * theme.spacing.unit,
            maxWidth: 1200
        }
    })

const Divider = (props: WithStyles<typeof styles>) => <div className={props.classes.divider}><div style={{height: 1, width: 1}}/></div>

export default withStyles(styles)(Divider)

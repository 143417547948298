import * as React from 'react'
import {AppBar, Hidden, Theme, Toolbar, WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import {useTranslation} from 'react-i18next'
import Button from '@material-ui/core/Button/Button'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'
import Typography from '@material-ui/core/Typography/Typography'
import BarButton from './BarButton'
import withStyles from '@material-ui/core/styles/withStyles'
const logo = require('../assets/logo.svg')

const styles = () =>
    createStyles({
        homeButton : {
            textTransform: 'none',
            color: '#fff',
            display: 'flex'
        },
        logo: {
            width: 28,
            height: 28,
            marginRight: 8
        }
    })

const TopAppBar = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <AppBar>
            <Toolbar>
                <Button className={props.classes.homeButton} component={(props) => <Link to={AppRoute.home} {...props as LinkProps}/>}>
                    <img src={logo} className={props.classes.logo}/>
                    <Hidden xsDown>
                        <Typography variant="h6" color="inherit">
                            {t('title')}
                        </Typography>
                    </Hidden>
                </Button>

                <div style={{flex: 1}}/>
                <Hidden smDown>
                    <BarButton route={AppRoute.contact}>
                        {t('contact.phone.value')}
                    </BarButton>
                </Hidden>
                <BarButton route={AppRoute.features}>
                    {t('features.title')}
                </BarButton>
                <BarButton route={AppRoute.pricing}>
                    {t('pricing.title')}
                </BarButton>
                <BarButton action={() => window.open(t('appUrl'), '_blank')}>
                    {t('app.title')}
                </BarButton>
            </Toolbar></AppBar>
    )
}

export default withStyles(styles)(TopAppBar)
import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {TypographyProps} from '@material-ui/core/Typography'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginBottom: theme.spacing.unit * 4
        }
    })

const TextParagraph = (props: TypographyProps & WithStyles<typeof styles>) => (
    <Typography color="textSecondary" className={props.classes.root}>
        {props.children}
    </Typography>
)

export default withStyles(styles)(TextParagraph)

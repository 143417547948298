import * as React from 'react';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import {Color} from '@material-ui/core'
import 'typeface-lato'
import {BrowserRouter} from 'react-router-dom'

const primary: Partial<Color> = {
    50: '#464655',
    100: '#464655',
    200: '#464655',
    300: '#464655',
    400: '#464655',
    500: '#464655',
    600: '#464655',
    700: '#464655',
    800: '#464655',
    900: '#464655',
    A100: '#464655',
    A200: '#464655',
    A400: '#464655',
    A700: '#464655'
}

const secondary: Partial<Color> = {
    50: '#7ACC29',
    100: '#7ACC29',
    200: '#7ACC29',
    300: '#7ACC29',
    400: '#7ACC29',
    500: '#7ACC29',
    600: '#7ACC29',
    700: '#7ACC29',
    800: '#7ACC29',
    900: '#7ACC29',
    A100: '#7ACC29',
    A200: '#7ACC29',
    A400: '#7ACC29',
    A700: '#7ACC29'
}

const defaultTheme = createMuiTheme()
const theme = createMuiTheme({
    palette: {
        primary: primary,
        secondary: secondary,
        text: {
            primary: '#2A2A33',
            secondary: '#666'
        },
        background: {
            default: '#fff'
        },
        getContrastText: () => '#FFF'
    },
    typography: {
        useNextVariants: true,
        fontFamily: '"Lato", sans-serif',
        display1: {
            color: 'black'
        }
    },

    overrides: {
        MuiButton: {
            containedSecondary: {
                color: 'white'
            }
        },
        MuiTypography: {
            root: {
                whiteSpace: 'pre-line'
            },
            h6:{
                lineHeight: 1.3
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: 48,
                [defaultTheme.breakpoints.up('sm')]: {
                    minHeight: 48
                },
            }
        }
    }
});

function withRoot<P>(Component: React.ComponentType<P>) {
    function WithRoot(props: P) {
        // MuiThemeProvider makes the theme available down the React tree
        // thanks to React context.
        return (
            <BrowserRouter>
                <MuiThemeProvider theme={theme}>
                    {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                    <CssBaseline />
                    <Component {...props} />
                </MuiThemeProvider>
            </BrowserRouter>
        );
    }

    return WithRoot;
}

export default withRoot;
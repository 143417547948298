import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            maxWidth: 700, marginLeft: 'auto', marginRight: 'auto',
            marginTop: 4 * theme.spacing.unit, marginBottom: 4 * theme.spacing.unit, paddingLeft: 2 * theme.spacing.unit, paddingRight: 2 * theme.spacing.unit
        }
    })

const TextContainer = (props: React.HTMLAttributes<HTMLDivElement> & WithStyles<typeof styles>) => <div className={props.classes.root} {...props}/>

export default withStyles(styles)(TextContainer)

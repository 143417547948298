import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Check from '@material-ui/icons/Check'

const styles = (theme: Theme) =>
    createStyles({
        checkmark: {
            color: theme.palette.secondary.main
        }
    })

const CheckedText = (props: WithStyles<typeof styles> & {children?: React.ReactNode}) => {
    const {t} = useTranslation()

    return (
        <div style={{display: 'flex'}}>
            <Check className={props.classes.checkmark}/>
            <Typography variant="body1">
                {props.children}
            </Typography>
        </div>
    )
}

export default withStyles(styles)(CheckedText)

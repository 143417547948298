import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import Footer from '../components/Footer'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'
import PageContainer from '../components/PageContainer'
import TextContainer from '../components/TextContainer'
import ContactFooter from '../components/ContactFooter'

const styles = () =>
    createStyles({

    })

const Contact = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <PageContainer>
            <TextContainer>
                <br/>
                <Typography variant="h4">{t('contact.pageTitle')}</Typography>
                <Typography variant="body1">
                    <br/>
                    <b>{t('contact.address')}</b><br/><br/>

                    <small>{t('contact.email.title')}</small><br/>
                    <b style={{cursor: 'pointer'}} onClick={() => window.location.href=`mailto:${t('contact.email.value')}`}>{t('contact.email.value')}</b><br/><br/>

                    <small>{t('contact.phone.title')} </small><br/>
                    <b style={{cursor: 'pointer'}} onClick={() => window.location.href=`tel:${t('contact.phone.value')}`}>{t('contact.phone.value')}</b><br/><br/>

                    <small>{t('contact.ceo.title')}</small><br/>
                    <b>{t('contact.ceo.value')}</b><br/>

                </Typography>
            </TextContainer>

            <div style={{flexGrow: 1}}/>
            <ContactFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(Contact)

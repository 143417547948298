import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Avatar, Grid, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import TextContainer from '../components/TextContainer'
import PageContainer from '../components/PageContainer'
import TextTitle from '../components/TextTitle'
import TextParagraph from '../components/TextParagraph'
import Divider from '../components/Divider'
import ContactFooter from '../components/ContactFooter'

const christian = require('../assets/about/christian.jpg')
const hanna = require('../assets/about/hanna.jpg')

const styles = () =>
    createStyles({
        avatarContainer: {
            display: 'flex', flexDirection: 'column', alignItems: 'center'
        },
        avatar: {
            width: 120, height: 120
        },
        avatarTitle: {
            marginTop: 8
        },
        avatarSubtitle: {
            marginTop: 4
        }
    })

const About = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <PageContainer>
            <TextContainer>
                <Typography variant="h4">{t('title')}</Typography>
                <br/><br/>

                <TextTitle>
                    {t('about.intro.title')}
                </TextTitle>
                <TextParagraph>
                    {t('about.intro.text')}
                    <br/><br/>
                    <i>{t('about.intro.signature')}</i>
                </TextParagraph>

                <Divider/>
                <Grid container>
                    <Grid item xs={6} sm={6} className={props.classes.avatarContainer}>
                        <Avatar src={christian} className={props.classes.avatar}/>
                        <Typography variant="h6" className={props.classes.avatarTitle}>
                            {t('about.christian.name')}
                        </Typography>
                        <Typography variant="body2" className={props.classes.avatarSubtitle}>
                            {t('about.christian.position')}
                        </Typography>
                    </Grid>

                    <Grid item xs={6} sm={6} className={props.classes.avatarContainer}>
                        <Avatar src={hanna} className={props.classes.avatar}/>
                        <Typography variant="h6" className={props.classes.avatarTitle}>
                            {t('about.hanna.name')}
                        </Typography>
                        <Typography variant="body2" className={props.classes.avatarSubtitle}>
                            {t('about.hanna.position')}
                        </Typography>
                    </Grid>
                </Grid>
                <Divider/>

                <TextTitle>
                    {t('about.mission.title')}
                </TextTitle>
                <TextParagraph>
                    {t('about.mission.text')}
                </TextParagraph>
            </TextContainer>

            <div style={{flexGrow: 1}}/>
            <ContactFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(About)

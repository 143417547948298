
export default {
    home: '/',
    contact: '/contact',
    features: '/features',
    pricing: '/pricing',
    app: '/app',
    imprint: '/imprint',
    legal: '/legal',
    privacy: '/privacy',
    about: '/about',
}
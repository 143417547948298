import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Theme, withStyles, WithStyles} from '@material-ui/core'


const styles = (theme: Theme) =>
    createStyles({
        root: {
            background: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center',
            position: 'absolute',
            marginTop: 48,
            width: '100%', height: 'calc(100% - 48px)',
            overflowY: 'auto',
            backgroundColor: theme.palette.background.default
        }
    })

const PageContainer = (props: React.HTMLAttributes<HTMLDivElement> & WithStyles<typeof styles>) => <div className={props.classes.root} {...props}/>

export default withStyles(styles)(PageContainer)

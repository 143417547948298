import * as React from 'react'
import {useTranslation} from 'react-i18next'
import Footer from './Footer'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'

const ContactFooter = () => {
    const {t} = useTranslation()

    return (
        <Footer
            title={t('contact.teaser.title')}
            subtitle={t('contact.teaser.text')}
            buttonTitle={t('contact.teaser.button')}
            buttonAction={() => window.location.href=`tel:${t('contact.phone.value')}`}
            buttonComponent={props => <Link to={AppRoute.contact} {...props as LinkProps}/>}
        />
    )
}

export default ContactFooter

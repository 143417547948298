import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import {Link, LinkProps} from 'react-router-dom'
import AppRoute from '../AppRoute'
import Footer from './Footer'

const StartFooter = () => {
    const {t} = useTranslation()

    return (
        <Footer
            title={t('trial.teaser.title')}
            subtitle={t('trial.teaser.text')}
            buttonTitle={t('trial.teaser.button')}
            buttonAction={() => window.open(t('appUrl'), '_blank')}
        />
    )
}

export default StartFooter

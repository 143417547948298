import i18next from 'i18next'
import { initReactI18next } from 'react-i18next';
import common_de from './translations/de/common.json'

i18next
    .use(initReactI18next)
    .init({
        interpolation: {
            escapeValue: false
        },
        lng: 'de',
        resources: {
            de: {
                translation: common_de
            }
        }
    })

export default i18next
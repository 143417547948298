import * as React from 'react'
import {Button, WithStyles} from '@material-ui/core'
import createStyles from '@material-ui/core/styles/createStyles'
import {Link, LinkProps} from 'react-router-dom'
import withStyles from '@material-ui/core/styles/withStyles'
import {ButtonProps} from '@material-ui/core/Button'

interface Props {
    route?: string
    action?: () => void
}

const styles = () =>
    createStyles({
        barButton : {
            textTransform: 'none',
            color: '#fff'
        }
    });

const BarButton = (props: WithStyles<typeof styles> & Props & ButtonProps) => {
    let component: undefined | React.ElementType<ButtonProps> = props.route ? p => <Link to={props.route} {...p as LinkProps}/> : undefined
    return (
        <Button className={props.classes.barButton} component={component} onClick={props.action}>
            {props.children}
        </Button>
    )
}

export default withStyles(styles)(BarButton)
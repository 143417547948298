import * as React from 'react'
import {ButtonProps} from '@material-ui/core/Button'
import createStyles from '@material-ui/core/styles/createStyles'
import {Button, Hidden, Theme, Typography, WithStyles, withStyles} from '@material-ui/core'
import BarButton from './BarButton'
import AppRoute from '../AppRoute'
import {useTranslation} from 'react-i18next'

const footerImage = require('../assets/footer.svg')

interface Props {
    title: string
    subtitle: string
    buttonTitle: string
    buttonAction?: () => void
    buttonComponent?: React.ComponentType<ButtonProps>
}

const styles = (theme: Theme) =>
    createStyles({
        root: {
            marginTop: 6*theme.spacing.unit,
            width: '100%',
            position: 'relative',
        },
        backgroundContainer: {
            width: '100%', height: 300, display: 'flex', flexDirection: 'column', overflow: 'hidden'
        },
        backgroundImage: {
            width: '100%', height: 'auto',
            objectFit: 'cover', objectPosition: '0 0'
        },
        backgroundFillUp: {
            width: '100%', flexGrow: 1, background: theme.palette.primary.main, marginTop: -1
        },
        callToActionContainer: {
            position: 'absolute',
            bottom: 0,
            right: 0,
            left: 0,
            height: '95%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center'
        },
        bottomBar: {
            position: 'absolute',
            bottom: 0,
            left: 0,
            display: 'flex',
            alignItems: 'flex-end'
        },
        title: {
            color: 'white',
            textTransform: 'uppercase'
        },
        subtitle: {
            color: 'white',
            maxWidth: 400,
            margin: theme.spacing.unit,
            marginBottom: 3 * theme.spacing.unit
        },
        button: {
            minWidth: 300
        },
        bottomBackground: {
            position: 'absolute',
            background: theme.palette.primary.main,
            width: '100%',
            height: 150,
            bottom: 0
        }
    })

const Footer = (props: Props & WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <div className={props.classes.root}>

                <div className={props.classes.backgroundContainer}>
                    <img src={footerImage} className={props.classes.backgroundImage}/>
                    <div className={props.classes.backgroundFillUp}/>
                </div>

                <div className={props.classes.callToActionContainer}>
                    <Typography variant="h4" className={props.classes.title}>
                        {props.title}
                    </Typography>
                    <Typography className={props.classes.subtitle}>
                        {props.subtitle}
                    </Typography>
                    <Button variant="contained" color="secondary" className={props.classes.button} onClick={props.buttonAction} component={props.buttonComponent} >
                        {props.buttonTitle}
                    </Button>
                </div>

                <div style={{position: 'absolute', bottom: 0, left: 0, display: 'flex', alignItems: 'flex-end'}}>
                    <Hidden xsDown>
                        <BarButton route={AppRoute.contact}>
                            {t('contact.title') }
                        </BarButton>
                    </Hidden>
                    <BarButton route={AppRoute.imprint}>
                        {t('imprint.title') }
                    </BarButton>
                    <BarButton route={AppRoute.legal}>
                        {t('legal.title') }
                    </BarButton>
                    <BarButton route={AppRoute.about}>
                        {t('about.title') }
                    </BarButton>
                    <BarButton route={AppRoute.privacy}>
                        {t('privacy.title') }
                    </BarButton>

            </div>
        </div>
    )
}

export default withStyles(styles)(Footer)
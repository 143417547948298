import * as React from 'react'
import {useEffect} from 'react'
import {Theme} from '@material-ui/core/styles/createMuiTheme'
import createStyles from '@material-ui/core/styles/createStyles'
import {WithStyles} from '@material-ui/core/styles/withStyles'
import AppRoute from '../AppRoute'
import {BrowserRouter, Route} from 'react-router-dom'
import TopAppBar from '../components/TopAppBar'
import {withStyles} from '@material-ui/core'
import Home from './Home'
import withRoot from '../withRoot'
import Contact from './Contact'
import Legal from './Legal'
import Privacy from './Privacy'
import About from './About'
import Features from './Features'
import Pricing from './Pricing'
import {useTranslation} from 'react-i18next'

const styles = (theme: Theme) =>
    createStyles({
        root: {
            width: '100%', height: '100%'
        }
    });

const App = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    useEffect(() => {
        document.title = t('title')
    })

    return (
        <BrowserRouter>
            <div className={props.classes.root}>
                <TopAppBar/>
                <Route path="/" exact={true} component={Home}/>
                <Route path={AppRoute.contact} component={Contact}/>
                <Route path={AppRoute.features} component={Features}/>
                <Route path={AppRoute.pricing} component={Pricing}/>
                <Route path={AppRoute.imprint} component={Contact}/>
                <Route path={AppRoute.legal} component={Legal}/>
                <Route path={AppRoute.about} component={About}/>
                <Route path={AppRoute.privacy} component={Privacy}/>
            </div>
        </BrowserRouter>
    )
}

export default withRoot(withStyles(styles)(App))
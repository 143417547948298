import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Button, Grid, Paper, Theme, Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import PageContainer from '../components/PageContainer'
import TextContainer from '../components/TextContainer'
import TextTitle from '../components/TextTitle'
import CheckedText from '../components/CheckedText'
import classNames from 'classnames'
import ContactFooter from '../components/ContactFooter'
import StartFooter from '../components/StartFooter'

const sealImage = require('../assets/sealAvatar.png')

const styles = (theme: Theme) =>
    createStyles({
        container: {
            textAlign: 'center'
        },
        gridElement: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
        },
        gridElementLeft: {
            borderRight: `1px solid ${theme.palette.grey.A100}`
        },
        sealAvatar: {
            width: 125,
            height: 125
        },
        paper: {
            marginTop: 16, padding: 16
        },
        uppercase: {
            textTransform: 'uppercase'
        },
        taxContainer: {
            marginTop: 16, borderTop: `1px solid ${theme.palette.grey.A100}`,
            paddingTop: 16
        }
    })

const Pricing = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()

    return (
        <PageContainer>
            <TextContainer>
                <div className={props.classes.container}>
                    <Typography variant="h4">{t('pricing.pageTitle')}</Typography>
                    <br/>
                    <Typography variant="body1">{t('pricing.trial')}</Typography>
                    <br/>
                    <img src={sealImage} className={props.classes.sealAvatar}/>
                    <br/><br/><br/><br/>
                    <TextTitle><b className={props.classes.uppercase}>{t('pricing.afterTrial')}</b></TextTitle>

                    <Paper elevation={8} className={props.classes.paper}>
                        <Grid container>
                            <Grid item xs={6} sm={6} className={classNames(props.classes.gridElement, props.classes.gridElementLeft)}>
                                <Typography variant="h6" className={props.classes.uppercase}>
                                    <b>{t('pricing.monthly.price')}</b>
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {t('pricing.perApartment')}
                                </Typography>
                                <br/>
                                <CheckedText>{t('pricing.monthly.advantage')}</CheckedText>
                            </Grid>

                            <Grid item xs={6} sm={6} className={props.classes.gridElement}>
                                <Typography variant="h6" className={props.classes.uppercase}>
                                    <b>{t('pricing.yearly.price')}</b>
                                </Typography>
                                <Typography variant="body1" color="textSecondary">
                                    {t('pricing.perApartment')}
                                </Typography>
                                <br/>
                                <CheckedText>{t('pricing.yearly.advantage')}</CheckedText>
                            </Grid>
                        </Grid>

                        <div className={props.classes.taxContainer}>
                            <Typography variant="body1" color="textSecondary">
                                {t('pricing.vatInfo')}
                            </Typography>
                        </div>
                    </Paper>
                </div>
            </TextContainer>

            <div style={{flexGrow: 1}}/>
            <StartFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(Pricing)

import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Grid, Theme, Typography, withStyles, WithStyles} from '@material-ui/core'

interface Props {
    icon: any,
    title: string,
    text: string
}

const styles = (theme: Theme) =>
    createStyles({
        container: {
            textAlign: 'center', padding: 2 * theme.spacing.unit
        },
        title: {
            marginBottom: 2 * theme.spacing.unit, textTransform: 'uppercase'
        },
        icon: {
            width: 72, height: 72
        }
    })

const GridFeature = (props: Props & WithStyles<typeof styles>) => {
    return (
        <Grid item xs={12} sm={4} className={props.classes.container}>
            <img src={props.icon} className={props.classes.icon}/>
            <Typography variant="h6" className={props.classes.title}>{props.title}</Typography>
            <Typography>{props.text}</Typography>
        </Grid>
    )
}

export default withStyles(styles)(GridFeature)

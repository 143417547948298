import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import TextContainer from '../components/TextContainer'
import TextTitle from '../components/TextTitle'
import TextParagraph from '../components/TextParagraph'
import ContactFooter from '../components/ContactFooter'
import PageContainer from '../components/PageContainer'

const styles = () =>
    createStyles({})

const Privacy = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()
    const terms = t('privacy.terms', { returnObjects: true }) as [string]

    return (
        <PageContainer>
            <TextContainer>
                <Typography variant="h4">{t('privacy.title')}</Typography>

                <br/><br/>
                <TextParagraph>
                    {t('privacy.intro')}
                </TextParagraph>

                <TextTitle>
                    {t('legal.provider.title')}
                </TextTitle>
                <TextParagraph>
                    {t('contact.address')}
                    {t('legal.provider.title')}
                </TextParagraph>

                {terms.map((text, index) => (index % 2 === 0) ?
                    <TextTitle key={index}>{text}</TextTitle> : <TextParagraph key={index}>{text}</TextParagraph>
                )}
            </TextContainer>

            <div style={{flexGrow: 1}}/>
            <ContactFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(Privacy)

import * as React from 'react'
import createStyles from '@material-ui/core/styles/createStyles'
import {Typography, withStyles, WithStyles} from '@material-ui/core'
import {useTranslation} from 'react-i18next'
import TextContainer from '../components/TextContainer'
import PageContainer from '../components/PageContainer'
import StartFooter from '../components/StartFooter'
import TextTitle from '../components/TextTitle'
import TextParagraph from '../components/TextParagraph'
import CheckedText from '../components/CheckedText'

const styles = () =>
    createStyles({})

const Features = (props: WithStyles<typeof styles>) => {
    const {t} = useTranslation()
    const features = t('features.list', { returnObjects: true }) as [string]
    const terms = t('features.start.list', { returnObjects: true }) as [string]

    return (
        <PageContainer>
            <TextContainer>
                <Typography variant="h4">{t('features.title')}</Typography>
                <br/><br/>

                <TextTitle>{t('features.listTitle')}</TextTitle>
                {features.map((text, index) => <CheckedText key={index}>{text}</CheckedText>)}
                <br/><br/>

                <TextTitle>{t('features.start.title')}</TextTitle>
                {terms.map((text, index) => <CheckedText key={index}>{text}</CheckedText>)}
            </TextContainer>

            <div style={{flexGrow: 1}}/>
            <StartFooter/>
        </PageContainer>
    )
}

export default withStyles(styles)(Features)
